import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom-v5-compat';
import { replace } from 'redux-first-history';

import { ErrorCode, isErrorCode } from 'services/utilities/isApiError';

/**
 * - Extracts the `errorCode` query parameter from the URL.
 * - Clears the `errorCode` query parameter from the URL so any subsequent page refreshes don't show the error message and don't block the user from logging in (the error might not be relevant anymore).
 */
export function useErrorCodeParam() {
    const { search, pathname } = useLocation();
    const [errorCode, setErrorCode] = useState<ErrorCode | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (errorCode !== null) {
            return;
        }

        const searchParams = new URLSearchParams(search);
        const errorCodeParam = searchParams.get('errorCode');

        if (isErrorCode(errorCodeParam)) {
            setErrorCode(errorCodeParam);

            searchParams.delete('errorCode');
            dispatch(
                replace({
                    pathname,
                    search: searchParams.toString(),
                }),
            );
        } else {
            setErrorCode(null);
        }
    }, [search, pathname, dispatch, errorCode]);

    return errorCode;
}
