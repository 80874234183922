import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { useUser } from 'modules/auth';

import { identifyUser, trackPaymentSuccess } from '../utils/woopra';

export const useWoopra = () => {
    const user = useUser();

    const [searchParams, setSearchParams] = useSearchParams();
    const isPaymentSuccess = searchParams.get('payment') === 'true';

    useEffect(() => {
        identifyUser(user);

        if (isPaymentSuccess) {
            trackPaymentSuccess();
            setSearchParams({});
        }
    }, [isPaymentSuccess, user, setSearchParams]);
};
