import Cookies from 'js-cookie';

import { logger } from 'config/logger';
import type { User } from 'types';

const WOOPRA_TRACKER_COOKIE_NAME = 'wooTracker';
const WOOPRA_USER_ALREADY_IDENTIFIED_COOKIE_NAME = 'woopraIdentified';

const createWoopraUrl = (pathname: string) => {
    const url = new URL('https://www.woopra.com/');

    url.pathname = pathname;

    return url;
};

export const identifyUser = async (user: User | null) => {
    const woopraTrackerCookieValue = Cookies.get(WOOPRA_TRACKER_COOKIE_NAME);
    const isWoopraAlreadyIdentified = Cookies.get(WOOPRA_USER_ALREADY_IDENTIFIED_COOKIE_NAME);

    if (user && woopraTrackerCookieValue && !isWoopraAlreadyIdentified) {
        try {
            const identifyUrl = createWoopraUrl('/track/identify');

            identifyUrl.searchParams.append('host', 'passwd.team');
            identifyUrl.searchParams.append('cookie', woopraTrackerCookieValue);
            identifyUrl.searchParams.append('cv_email', user.email);
            identifyUrl.searchParams.append('cv_name', user.name);
            identifyUrl.searchParams.append('cv_company', user.domain ?? '');

            await fetch(identifyUrl);

            Cookies.set(WOOPRA_USER_ALREADY_IDENTIFIED_COOKIE_NAME, 'true', {
                secure: true,
                expires: 365,
                domain: '.passwd.team',
            });
        } catch (error) {
            logger.error(error);
        }
    }
};

export const trackPaymentSuccess = async () => {
    const woopraTrackerCookieValue = Cookies.get(WOOPRA_TRACKER_COOKIE_NAME);

    if (woopraTrackerCookieValue) {
        try {
            const trackUrl = createWoopraUrl('/track/ce');

            trackUrl.searchParams.append('host', 'passwd.team');
            trackUrl.searchParams.append('cookie', woopraTrackerCookieValue);
            trackUrl.searchParams.append('event', 'payment');

            await fetch(trackUrl);
        } catch (error) {
            logger.error(error);
        }
    }
};
