import type { locationChangeAction } from 'redux-first-history/build/es6/actions';

import type { createStore } from 'modules/core/modules/redux';

export type Store = ReturnType<typeof createStore>['store'];

export type AppState = ReturnType<Store['getState']>;

export interface User {
    id: string;
    name: string;
    email: string;
    isAdmin?: boolean;
    domain?: string;
}

export enum AccessRole {
    EDITOR = 'editor',
    VIEWER = 'viewer',
    VIEWER_AUTOFILL_ONLY = 'autofillOnly',
}

export enum AccessPermission {
    READ = 'read',
    WRITE = 'write',
    AUTOFILL_ONLY = 'autofillOnly',
}

export interface EntityWithPermissions {
    id: string;
    accessPermissions: AccessPermission[];
}

export interface ApiError<D extends Record<string, any> = {}, C extends string = string> {
    code: number;
    message: string;
    errorCode: C;
    errorClass: string;
    errorData: D;
}

export interface SaasLimits {
    maxSecrets: number;
    beforeImport: number;
    afterImport: number;
    importAmount: number;
}

export type LocationChangeAction = ReturnType<typeof locationChangeAction>;
