import { IS_DEMO, IS_SAAS } from 'constants/index';

import { config } from 'config/config';

import { useTidio } from 'modules/chat/hooks';

import { ExtensionPrompt, shouldDisplayExtensionPromo } from 'modules/extension';
import { UserFeedbackPrompt } from 'modules/user-feedback';
import { ReviewPromptModal } from 'modules/review-prompt';
import { useFetchSettings } from 'modules/settings';
import { SubscriptionUpgradeModal } from 'modules/saas-upgrade';
import { useWoopra } from 'modules/tracking';

import { ResponsiveApplication } from '../ResponsiveApplication';

const isClientInstance = !IS_DEMO && !IS_SAAS;

export const AuthorizedApplication = () => {
    useFetchSettings();

    useWoopra();
    useTidio();

    return (
        <>
            {shouldDisplayExtensionPromo && isClientInstance ? <ExtensionPrompt /> : null}
            {config.demo.enable ? null : (
                <>
                    <UserFeedbackPrompt />
                    <ReviewPromptModal />
                </>
            )}
            <SubscriptionUpgradeModal />
            <ResponsiveApplication />
        </>
    );
};
