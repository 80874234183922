import type { PetrusConfig } from '@ackee/petrus';
import jwtDecode from 'jwt-decode';
import { replace } from 'redux-first-history';
import { put } from 'redux-saga/effects';

import config from 'config';
import { api } from 'config/antonio';
import { ErrorCode, isApiAntonioError } from 'services/utilities/isApiError';
import type { User } from 'types';
import { waitForOnline } from 'modules/network';

import type { GoogleIdToken } from '../../types';
import { getDemoUser } from '../helpers/getDemoUser';
import { isDemoAccessToken } from '../utils';

function* fetchUserInfo() {
    yield* waitForOnline();

    try {
        const {
            data: { isAdmin, domain },
        } = yield* api.get<User>(config.api.me);

        return { isAdmin, domain };
    } catch (error) {
        const handledErrors = new Set<string>([
            ErrorCode.CONFIGURED_SUPER_ADMIN_IS_NOT_AN_ADMIN,
            ErrorCode.CONFIGURED_SUPER_ADMIN_USER_DOESNT_EXIST_IN_THE_WORKSPACE,
            ErrorCode.AUTH_CLIENT_IS_NOT_CONFIGURED,
        ]);

        if (isApiAntonioError(error) && handledErrors.has(error.data.errorCode)) {
            yield put(
                replace(
                    `${config.routes.home}?${new URLSearchParams({
                        errorCode: error.data.errorCode,
                    })}`,
                ),
            );
        }

        throw error;
    }
}

// @ts-expect-error
export const getAuthUser: PetrusConfig['handlers']['getAuthUser'] = function* getAuthUser(tokens) {
    // @ts-expect-error
    if (isDemoAccessToken(tokens.accessToken)) {
        const { user } = getDemoUser(tokens.accessToken.userId);

        return user;
    }

    // @ts-expect-error
    const idToken = jwtDecode<GoogleIdToken>(tokens.accessToken.idToken);
    const { isAdmin, domain } = yield* fetchUserInfo();

    return {
        id: idToken.sub,
        name: idToken.name,
        email: idToken.email,
        isAdmin,
        domain,
    };
};
