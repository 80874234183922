import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useFela } from 'react-fela';
import classNames from 'classnames';

import { Dropdown as AntDropdown } from 'antd';

import { Icon } from '../Icon';

import * as felaRules from './Dropdown.rules';

export interface DropdownProps {
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    items: {
        key: string;
        active: boolean;
        label: ReactNode;
        onClick: () => void;
    }[];
    children: ReactNode;
    customStyle?: typeof felaRules.dropdown;
}

export const Dropdown = ({ open = false, onOpenChange, items, children, customStyle }: DropdownProps) => {
    const { css } = useFela();
    const [visible, setVisibility] = useState(open);

    useEffect(() => {
        setVisibility(open);
    }, [open]);

    return (
        <AntDropdown
            overlayClassName={css(felaRules.dropdown, customStyle)}
            // @ts-expect-error
            getPopupContainer={triggerNode => triggerNode.parentElement}
            open={visible}
            onOpenChange={o => {
                setVisibility(o);
                onOpenChange?.(o);
            }}
            trigger={['hover']}
            placement="bottom"
            menu={{
                items: items.map(item => ({
                    key: item.key,
                    label: item.label,
                    className: classNames({
                        active: item.active,
                    }),
                    onClick: () => {
                        item.onClick();
                        setVisibility(false);
                    },
                })),
            }}
        >
            <button
                className={css(felaRules.button)}
                onClick={e => {
                    e.preventDefault();
                }}
            >
                {children}
                <Icon type="caret" customStyle={visible ? felaRules.caretOpened : felaRules.caret} />
            </button>
        </AntDropdown>
    );
};
